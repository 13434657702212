@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.dailyVolume {
    &__titleWrapper {
        display: flex;
        margin-bottom: 2vw;
        width: 100%;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 1vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        width: 15vw;
        margin-right: 2.75vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.14vw;
            line-height: $tablet32px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3.3vw;
            line-height: $mobile24px;
            margin-bottom: 2vw;
            width: 100%;
        }
    }

    &__nowDailyVolumeIs {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 1vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        width: 15vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.14vw;
            line-height: $tablet32px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3.3vw;
            line-height: $mobile24px;
            margin-bottom: 2vw;
            width: 100%;
        }

        &_red {
            color: $red;
        }
    }

    &__btnrefreshDailyPrediction {
        @include mainBtnStyle;
        @include greenBtnWithBorderAndBackground;
        font-family: $MontserratRegular;
        height: 3.91vw;
        width: fit-content;
        min-width: 15vw;
        margin-right: auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: $tablet30px 0;
            width: 31.3vw;
            height: $tablet60px;
            width: 50vw;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: 16vw;
            font-size: 3.2vw;
            line-height: 3.2vw;
            margin-bottom: 0;
            margin-bottom: 7vw;
        }

        &_spin {
            margin-left: 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-left: 2vw;
            }

            @media only screen and (max-width: 499px) {
                margin-left: 4vw;
            }
        }
    }
}

.inputWrapper {
    margin-bottom: 2vw;
    width: 15vw;
    position: relative;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        width: 23vw;
        margin-bottom: 4vw;
    }

    @media only screen and (max-width: 499px) {
        margin-bottom: 7vw;
        width: 100%;
    }

    &_invalid {
        color: $red;
        bottom: -1.3vw;
        font-family: $MontserratRegular;
        font-weight: 400;
        font-size: 0.8vw;
        width: fit-content;
        position: absolute;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
            bottom: -15px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
            bottom: -15px;
        }
    }

    &_input {
        height: 3.91vw;
        line-height: 3.91vw;
        background: #f2f3f9;
        border-radius: 0.1vw;
        font-family: $MontserratRegular;
        font-size: 1.1vw;
        color: $textDarkLight;
        border-radius: 0.1vw;
        border: 1px solid #f2f3f9;
        outline: none;
        padding: 0 1vw;
        margin-top: 0.7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 100%;
            height: $tablet60px;
            line-height: $tablet60px;
            margin-top: $tablet10px;
            font-size: $tablet16px;
            padding: $tablet12px;
            border: 1px solid #f2f3f9;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            max-width: 100%;
            border: 1px solid #f2f3f9;
            height: $mobile60px;
            line-height: $mobile60px;
            margin-top: $mobile10px;
            font-size: $mobile16px;
            padding: $mobile12px;
        }

        &:hover,
        &:active,
        &:focus {
            border: 1px solid $blueDark;

            @media only screen and (max-width: 1024px) {
                border: 1px solid $blueDark;
            }
        }

        &::placeholder {
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $greyButton;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                border-radius: 2px;
            }

            @media only screen and (max-width: 499px) {
                border-radius: 2px;
                font-size: $mobile16px;
            }
        }

        &:disabled{
            background: #f2f3f9;
            border: 1px solid #f2f3f9;
            color: $greyButton;
        }
    }

    &_label {
        font-family: $MontserratRegular;
        font-size: 0.7vw;
        line-height: 0.7vw;
        color: $textDarkLight;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet10px;
            line-height: $tablet10px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile10px;
        }
    }
}
