.botTable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.1vw 2.1vw;
  margin-bottom: 0.5vw;
  transition: all 0.25s;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable {
    padding: 3.14vw 4.17vw;
    margin-bottom: 1.31vw;
    background: #ffffff;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 499px) {
  .botTable {
    padding: 8vw;
    margin-bottom: 2.67vw;
    background: #ffffff;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
  }
}
.botTable:hover {
  background: #ffffff;
  box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
}
.botTable__noBots {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.25vw;
  line-height: 2vw;
  color: #151719;
  text-align: center;
  margin-top: 4vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__noBots {
    font-size: 2.1vw;
    line-height: 3.14vw;
    margin-top: 6vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__noBots {
    margin-top: 8vw;
    font-size: 4.3vw;
    line-height: 6.4vw;
  }
}
.botTable__label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.8vw;
  color: #505761;
  margin-bottom: 0.5vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__label {
    font-size: 1.31vw;
    line-height: 1.31vw;
    margin-bottom: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__label {
    font-size: 2.67vw;
    line-height: 2.67vw;
    margin-bottom: 2.67vw;
  }
}
.botTable__id, .botTable__pair, .botTable__dailyVolume, .botTable__status {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0.5vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__id, .botTable__pair, .botTable__dailyVolume, .botTable__status {
    width: 14.35vw;
    padding-right: 10px;
    margin: 0;
  }
}
.botTable__id_green, .botTable__id_red, .botTable__pair_green, .botTable__pair_red, .botTable__dailyVolume_green, .botTable__dailyVolume_red, .botTable__status_green, .botTable__status_red {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  line-height: 1.2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__id_green, .botTable__id_red, .botTable__pair_green, .botTable__pair_red, .botTable__dailyVolume_green, .botTable__dailyVolume_red, .botTable__status_green, .botTable__status_red {
    font-size: 2.1vw;
    line-height: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__id_green, .botTable__id_red, .botTable__pair_green, .botTable__pair_red, .botTable__dailyVolume_green, .botTable__dailyVolume_red, .botTable__status_green, .botTable__status_red {
    font-size: 4.3vw;
    line-height: 6.4vw;
  }
}
.botTable__id_green, .botTable__pair_green, .botTable__dailyVolume_green, .botTable__status_green {
  color: #14d999;
}
.botTable__id_red, .botTable__pair_red, .botTable__dailyVolume_red, .botTable__status_red {
  color: #ff455c;
}
@media only screen and (max-width: 499px) {
  .botTable__logoWrapper, .botTable__id, .botTable__pair {
    width: 50%;
    margin: 0;
    padding-right: 2.67vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__dailyVolume, .botTable__status {
    width: 50%;
    margin: 5.34vw 0;
    padding-right: 2.67vw;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__pair, .botTable__dailyVolume {
    margin-right: 1.31vw;
  }
}
.botTable__id_value {
  width: 2vw;
}
@media only screen and (max-width: 1024px) {
  .botTable__id_value {
    width: 100%;
  }
}
.botTable__pair_value {
  width: 12vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1vw;
  cursor: pointer;
  position: relative;
  transition: all 0.25s;
}
.botTable__pair_value:hover {
  color: rgba(34, 124, 255, 0.48);
}
@media only screen and (max-width: 1024px) {
  .botTable__pair_value {
    white-space: nowrap !important;
    font-size: 15px;
    width: 100%;
  }
}
.botTable__dailyVolume_value {
  width: 9vw;
}
@media only screen and (max-width: 1024px) {
  .botTable__dailyVolume_value {
    width: 100%;
  }
}
.botTable__status_green, .botTable__status_red {
  width: 9vw;
}
@media only screen and (max-width: 1024px) {
  .botTable__status_green, .botTable__status_red {
    width: 100%;
  }
}
.botTable__dailyVolume_value {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  line-height: 1.2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #14d999;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__dailyVolume_value {
    font-size: 2.1vw;
    line-height: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__dailyVolume_value {
    font-size: 4.3vw;
    line-height: 6.4vw;
  }
}
.botTable__buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 1vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__buttonsWrapper {
    width: 100%;
    justify-content: flex-end;
    margin-top: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__buttonsWrapper {
    width: 100%;
    justify-content: space-between;
  }
}
.botTable__previewBtn {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: transparent;
  color: #007aff;
  border: 1px solid rgba(34, 124, 255, 0.48);
  height: 2.3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__previewBtn {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .botTable__previewBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__previewBtn {
    border: 1px solid white;
  }
}
.botTable__previewBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
@media only screen and (max-width: 1024px) {
  .botTable__previewBtn {
    border: 1px solid rgba(34, 124, 255, 0.48);
  }
}
@media only screen and (max-width: 499px) {
  .botTable__previewBtn {
    background-color: rgba(34, 124, 255, 0.48);
    color: #ffffff;
  }
}
.botTable__previewBtn:hover {
  background-color: rgba(34, 124, 255, 0.48);
  color: #ffffff;
}
.botTable__stopBtn, .botTable__deleteBtn, .botTable__previewBtn, .botTable__editBtn {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 2.3vw;
  width: fit-content;
  min-width: 4.1vw;
  padding: 0 0.5vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__stopBtn, .botTable__deleteBtn, .botTable__previewBtn, .botTable__editBtn {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botTable__stopBtn, .botTable__deleteBtn, .botTable__previewBtn, .botTable__editBtn {
    min-width: 14.35vw;
    height: 5.21vw;
    padding: 0;
    line-height: normal;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__stopBtn, .botTable__deleteBtn, .botTable__previewBtn, .botTable__editBtn {
    width: 33%;
    height: 8.7vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
}
.botTable__stopBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: transparent;
  color: #007aff;
  border: 1px solid rgba(34, 124, 255, 0.48);
}
@media only screen and (max-width: 1024px) {
  .botTable__stopBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__stopBtn {
    border: 1px solid white;
  }
}
.botTable__stopBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
@media only screen and (max-width: 1024px) {
  .botTable__stopBtn {
    border: 1px solid rgba(34, 124, 255, 0.48);
  }
}
@media only screen and (max-width: 499px) {
  .botTable__stopBtn {
    background-color: rgba(34, 124, 255, 0.48);
    color: #ffffff;
  }
}
.botTable__stopBtn:hover {
  background-color: rgba(34, 124, 255, 0.48);
  color: #ffffff;
}
.botTable__editBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: transparent;
  color: #14d999;
  border: 1px solid #14d999;
}
@media only screen and (max-width: 1024px) {
  .botTable__editBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__editBtn {
    border: 1px solid white;
  }
}
.botTable__editBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
@media only screen and (max-width: 1024px) {
  .botTable__editBtn {
    border: 1px solid #14d999;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__editBtn {
    background-color: #14d999;
    color: #ffffff;
  }
}
.botTable__editBtn:hover {
  background-color: #14d999;
  color: #ffffff;
}
.botTable__deleteBtn {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: transparent;
  color: #ff455c;
  border: 1px solid #ff455c;
}
@media only screen and (max-width: 1024px) {
  .botTable__deleteBtn {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__deleteBtn {
    border: 1px solid white;
  }
}
.botTable__deleteBtn:hover {
  background-color: transparent;
  transition: all 0.25s;
}
@media only screen and (max-width: 1024px) {
  .botTable__deleteBtn {
    border: 1px solid #ff455c;
  }
}
@media only screen and (max-width: 499px) {
  .botTable__deleteBtn {
    background-color: #ff455c;
    color: #ffffff;
  }
}
.botTable__deleteBtn:hover {
  background-color: #ff455c;
  color: #ffffff;
}

.previewIcon {
  cursor: pointer;
  width: 1.2vw;
  height: 1.2vw;
  min-width: 1.2vw;
  min-height: 1.2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .previewIcon {
    width: 2.1vw;
    height: 2.1vw;
    min-width: 2.1vw;
    min-height: 2.1vw;
  }
}
@media only screen and (max-width: 499px) {
  .previewIcon {
    width: 4.3vw;
    height: 4.3vw;
    min-width: 4.3vw;
    min-height: 4.3vw;
  }
}