.orders {
  width: 74.6vw;
  margin: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .orders {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.orders__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__titleWrapper {
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__titleWrapper {
    margin-bottom: 7vw;
  }
}
.orders__title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__title {
    font-size: 2.14vw;
    line-height: 4.17vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__title {
    font-size: 3.3vw;
    line-height: 6.4vw;
  }
}
.orders__wrapperInput {
  width: 12.7vw;
  position: relative;
  margin-right: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapperInput {
    width: 23vw;
    margin-right: 0;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapperInput {
    margin-bottom: 7vw;
    width: 100%;
  }
}
.orders__wrapperInput_invalid {
  color: #ff455c;
  bottom: -1.3vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8vw;
  width: fit-content;
  position: absolute;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapperInput_invalid {
    font-size: 1.31vw;
    line-height: 1.31vw;
    bottom: -15px;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapperInput_invalid {
    font-size: 2.67vw;
    line-height: 2.67vw;
    bottom: -15px;
  }
}
.orders__wrapperInput_disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.7 !important;
  margin-right: 2vw;
}
.orders__wrapper_showPairButton, .orders__wrapper_hidePairButton, .orders__wrapper_makePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton, .orders__wrapper_cancelAllButton {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  min-width: fit-content;
  height: 3.12vw;
  width: fit-content;
  min-width: 12.7vw;
  height: 3.91vw;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_showPairButton, .orders__wrapper_hidePairButton, .orders__wrapper_makePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton, .orders__wrapper_cancelAllButton {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_showPairButton, .orders__wrapper_hidePairButton, .orders__wrapper_makePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton, .orders__wrapper_cancelAllButton {
    margin: 3.91vw 0;
    width: 23vw;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_showPairButton, .orders__wrapper_hidePairButton, .orders__wrapper_makePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton, .orders__wrapper_cancelAllButton {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin: 8vw 0;
  }
}
.orders__wrapper_showPairButton, .orders__wrapper_makePairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_showPairButton, .orders__wrapper_makePairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_showPairButton, .orders__wrapper_makePairButton, .orders__wrapper_addRowButton, .orders__wrapper_makeAllButton {
    border: 1px solid white;
  }
}
.orders__wrapper_showPairButton:hover, .orders__wrapper_makePairButton:hover, .orders__wrapper_addRowButton:hover, .orders__wrapper_makeAllButton:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__wrapper_showPairButton:hover, .orders__wrapper_makePairButton:hover, .orders__wrapper_addRowButton:hover, .orders__wrapper_makeAllButton:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_showPairButton:hover, .orders__wrapper_makePairButton:hover, .orders__wrapper_addRowButton:hover, .orders__wrapper_makeAllButton:hover {
    border: 1px solid #14d999;
  }
}
.orders__wrapper_hidePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_cancelAllButton {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_hidePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_cancelAllButton {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_hidePairButton, .orders__wrapper_cancelPairButton, .orders__wrapper_cancelAllButton {
    border: 1px solid white;
  }
}
.orders__wrapper_hidePairButton:hover, .orders__wrapper_cancelPairButton:hover, .orders__wrapper_cancelAllButton:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__wrapper_hidePairButton:hover, .orders__wrapper_cancelPairButton:hover, .orders__wrapper_cancelAllButton:hover {
  border: 1px solid #ff455c;
  color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_hidePairButton:hover, .orders__wrapper_cancelPairButton:hover, .orders__wrapper_cancelAllButton:hover {
    border: 1px solid #ff455c;
  }
}
.orders__wrapper_cancelAllButton {
  margin-left: 1vw;
}
.orders__wrapper_makePairButton {
  margin-right: 1vw;
  margin-bottom: 0;
  margin-left: auto;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_makePairButton {
    margin-right: 0;
    margin-left: inherit;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_makePairButton {
    margin-right: 0;
  }
}
.orders__wrapper_cancelPairButton {
  margin-bottom: 0;
}
.orders__wrapper_cancelButton {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #ff455c;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  min-width: fit-content;
  height: 3.12vw;
  width: fit-content;
  min-width: 12.7vw;
  height: 3.91vw;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_cancelButton {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_cancelButton {
    border: 1px solid white;
  }
}
.orders__wrapper_cancelButton:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__wrapper_cancelButton:hover {
  border: 1px solid #ff455c;
  color: #ff455c;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_cancelButton:hover {
    border: 1px solid #ff455c;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_cancelButton {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_cancelButton {
    width: 23vw;
    height: 7.82vw;
    margin-left: auto;
    margin-top: 3.91vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_cancelButton {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin: 8vw 0;
  }
}
.orders__wrapper_makeAllButton {
  margin-left: auto;
}
.orders__wrapper_addPairButton {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.12vw;
  width: fit-content;
  min-width: 12.7vw;
  height: 3.91vw;
  margin-left: 3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_addPairButton {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_addPairButton {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_addPairButton {
    border: 1px solid white;
  }
}
.orders__wrapper_addPairButton:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.orders__wrapper_addPairButton:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_addPairButton:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_addPairButton {
    margin: 0 0 0 4vw;
    width: 23vw;
    height: 7.82vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_addPairButton {
    width: 100%;
    height: 16vw;
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin-bottom: 0;
    margin: 0 0 8vw 0;
  }
}
.orders__wrapper_addPairWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2vw;
}
.orders__wrapper_row {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_row {
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 0;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_row {
    flex-wrap: wrap;
  }
}
.orders__wrapper_row:not(:last-child) {
  margin-bottom: 3.3vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_row:not(:last-child) {
    margin-bottom: 8vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_row:not(:last-child) {
    margin-bottom: 14.7vw;
  }
}
.orders__wrapper_buttonBarWrapper {
  display: flex;
}
.orders__wrapper_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  margin-bottom: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_title {
    font-size: 1.31vw;
    line-height: 1.31vw;
    margin-bottom: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_title {
    font-size: 3.3vw;
    line-height: 2.67vw;
    margin-bottom: 2.67vw;
  }
}
.orders__wrapper_input {
  height: 3.91vw;
  line-height: 3.91vw;
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #151719;
  border-radius: 0.1vw;
  border: 1px solid #f2f3f9;
  outline: none;
  padding: 0 1vw;
  margin-top: 0.7vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_input {
    width: 100%;
    height: 7.82vw;
    line-height: 7.82vw;
    margin-top: 1.31vw;
    font-size: 2.1vw;
    padding: 1.6vw;
    border: 1px solid #f2f3f9;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #f2f3f9;
    height: 16vw;
    line-height: 16vw;
    margin-top: 2.67vw;
    font-size: 4.3vw;
    padding: 3.2vw;
  }
}
.orders__wrapper_input:hover, .orders__wrapper_input:active, .orders__wrapper_input:focus {
  border: 1px solid #007aff;
}
@media only screen and (max-width: 1024px) {
  .orders__wrapper_input:hover, .orders__wrapper_input:active, .orders__wrapper_input:focus {
    border: 1px solid #007aff;
  }
}
.orders__wrapper_input::placeholder {
  background: #f2f3f9;
  border-radius: 0.1vw;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1vw;
  color: #92969c;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_input::placeholder {
    font-size: 2.1vw;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_input::placeholder {
    border-radius: 2px;
    font-size: 4.3vw;
  }
}
.orders__wrapper_label {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  font-size: 0.7vw;
  line-height: 0.7vw;
  color: #151719;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orders__wrapper_label {
    font-size: 1.31vw;
    line-height: 1.31vw;
  }
}
@media only screen and (max-width: 499px) {
  .orders__wrapper_label {
    font-size: 2.67vw;
    line-height: 2.67vw;
  }
}

.botsWrapper {
  width: 74.6vw;
  margin: 1.8vw 7.85vw 0 3.85vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper {
    padding: 5.21vw 11.5vw 0 11.5vw;
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper {
    padding: 5.34vw 6.7vw 0 6.7vw;
    margin: 0;
    width: 100%;
  }
}
.botsWrapper__bot {
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__bot {
    margin-bottom: 5.21vw;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__bot {
    margin-bottom: 8vw;
  }
}
.botsWrapper__bot_title {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  color: #151719;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  margin-bottom: 1.9vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__bot_title {
    font-size: 2.14vw;
    line-height: 4.17vw;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__bot_title {
    font-size: 3.3vw;
    line-height: 6.4vw;
    margin-bottom: 7vw;
  }
}
.botsWrapper__startAll {
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: 0.05vw;
  padding: 0 1.2vw;
  transition: all 0.25s;
  border-radius: 0.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #14d999;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  height: 3.91vw;
  display: flex;
  justify-content: center;
  width: 15vw;
  margin-left: 2.75vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__startAll {
    font-size: 1.6vw;
    line-height: 1.6vw;
    padding: 2.35vw 3.14vw;
    letter-spacing: 1px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1024px) {
  .botsWrapper__startAll {
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__startAll {
    border: 1px solid white;
  }
}
.botsWrapper__startAll:hover {
  background-color: transparent;
  transition: all 0.25s;
}
.botsWrapper__startAll:hover {
  border: 1px solid #14d999;
  color: #14d999;
}
@media only screen and (max-width: 1024px) {
  .botsWrapper__startAll:hover {
    border: 1px solid #14d999;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__startAll {
    height: 7.82vw;
    margin: 2.61vw 0 2vw 7vw;
    flex: 1;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__startAll {
    height: 16vw;
    width: calc(50% - 4vw);
    font-size: 2.67vw;
    margin: 0 4vw 5.34vw 0;
  }
}
.botsWrapper__buttonsWrapper {
  display: flex;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__buttonsWrapper {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__buttonsWrapper {
    flex-wrap: wrap;
  }
}
.botsWrapper__greenRedButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vw;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .botsWrapper__greenRedButtonWrapper {
    margin-top: 2.61vw;
  }
}
@media only screen and (max-width: 499px) {
  .botsWrapper__greenRedButtonWrapper {
    width: 100%;
  }
}

.orderListWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vw;
  flex-wrap: wrap;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .orderListWrapper {
    margin: 3.91vw 0;
  }
}
@media only screen and (max-width: 499px) {
  .orderListWrapper {
    margin: 8vw 0;
  }
}
.orderListWrapper__fields {
  display: flex;
}
@media only screen and (max-width: 499px) {
  .orderListWrapper__fields {
    flex-wrap: wrap;
  }
}