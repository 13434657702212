@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.orders {
    width: 74.6vw;
    margin: 1.8vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__titleWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: 4vw;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: 7vw;
            flex-wrap: wrap;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.14vw;
            line-height: $tablet32px;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3.3vw;
            line-height: $mobile24px;
            width: 100%;
        }
    }

    &__row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 2vw;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }
    }

    &__wrapperInput {
        width: 23%;
        position: relative;

        @media only screen and (max-width: 499px) {
            margin-bottom: 7vw;
            width: 100%;
        }

        &_invalid {
            color: $red;
            bottom: -1.3vw;
            font-family: $MontserratRegular;
            font-weight: 400;
            font-size: 0.8vw;
            width: fit-content;
            position: absolute;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                bottom: -15px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                bottom: -15px;
            }
        }

        &_disabled {
            user-select: none;
            pointer-events: none;
            opacity: 0.7 !important;
            margin-right: 2vw;
        }

        &_disabled_left {
            user-select: none;
            pointer-events: none;
            opacity: 0.7 !important;
        }
    }

    &__confirmBtn {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        min-width: fit-content;
        height: 3.12vw;
        width: 23%;
        min-width: 12.7vw;
        height: 3.91vw;
        margin-right: auto;
        margin-left: 1.8vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 23%;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: 16vw;
            font-size: 3.2vw;
            line-height: 3.2vw;
            margin-bottom: 0;
            margin: $mobile30px 0;
        }
    }

    &__confirmBtn {
        @include greenBtnWithBorderAndBackground;
    }

    &__wrapper {
        &_title {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            margin-bottom: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                margin-bottom: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.3vw;
                line-height: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_input {
            height: 3.91vw;
            line-height: 3.91vw;
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw;
            margin-top: 0.7vw;
            width: 100%;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet60px;
                line-height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                padding: $tablet12px;
                border: 1px solid #f2f3f9;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: $mobile60px;
                line-height: $mobile60px;
                margin-top: $mobile10px;
                font-size: $mobile16px;
                padding: $mobile12px;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    border-radius: 2px;
                }

                @media only screen and (max-width: 499px) {
                    border-radius: 2px;
                    font-size: $mobile16px;
                }
            }
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }
    }
}

.line {
    height: 1px;
    background-color: #e8e8e8;
    margin: 4vw 0;
}

.noData {
    text-align: center;
    font-family: $MontserratRegular;
    font-size: 1.5vw;
    line-height: 1.5vw;
    color: $textDarkLight;
    margin-top: 4vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: 2.14vw;
        line-height: 4.17vw;
    }

    @media only screen and (max-width: 499px) {
        font-size: 3.3vw;
        line-height: 6.4vw;
    }
}
