@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixin';

.botTable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.1vw 2.1vw;
  margin-bottom: 0.5vw;
  transition: all 0.25s;

  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    padding: $tablet24px $tablet32px;
    margin-bottom: $tablet10px;
    background: $white;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 499px) {
    padding: $mobile30px;
    margin-bottom: $mobile10px;
    background: $white;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
  }

  &:hover {
    background: $white;
    box-shadow: 0 0 0.9vw rgba(0, 0, 0, 0.1);
  }

  &__noBots {
    font-family: $MontserratRegular;
    font-size: 1.25vw;
    line-height: 2vw;
    color: #151719;
    text-align: center;
    margin-top: 4vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      font-size: 2.1vw;
      line-height: 3.14vw;
      margin-top: 6vw;
    }

    @media only screen and (max-width: 499px) {
      margin-top: 8vw;
      font-size: 4.3vw;
      line-height: 6.4vw;
    }
  }

  &__label {
    font-family: $MontserratRegular;
    font-size: 0.8vw;
    color: $greyLight;
    margin-bottom: 0.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      font-size: $tablet10px;
      line-height: $tablet10px;
      margin-bottom: $tablet10px;
    }

    @media only screen and (max-width: 499px) {
      font-size: $mobile10px;
      line-height: $mobile10px;
      margin-bottom: $mobile10px;
    }
  }

  &__id,
  &__pair,
  &__dailyVolume,
  &__status {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 0.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      width: 14.35vw;
      padding-right: 10px;
      margin: 0;
    }

    &_green,
    &_red {
      font-family: $MontserratRegular;
      font-size: 1.1vw;
      line-height: 1.2vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
        line-height: $tablet20px;
      }

      @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile24px;
      }
    }

    &_green {
      color: $greenLight;
    }

    &_red {
      color: $red;
    }
  }

  &__logoWrapper,
  &__id,
  &__pair {
    @media only screen and (max-width: 499px) {
      width: 50%;
      margin: 0;
      padding-right: $mobile10px;
    }
  }

  &__dailyVolume,
  &__status {
    @media only screen and (max-width: 499px) {
      width: 50%;
      margin: $mobile20px 0;
      padding-right: $mobile10px;
    }
  }

  &__pair,
  &__dailyVolume {
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      margin-right: $tablet10px;
    }
  }

  &__id {
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      //margin-left: auto;
    }

    &_value {
      width: 2vw;

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &__pair {
    &_value {
      width: 12vw;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1vw;
      cursor: pointer;
      position: relative;
      transition: all 0.25s;
      &:hover {
        color: $blueLight;
      }
      @media only screen and (max-width: 1024px) {
        white-space: nowrap !important;
        font-size: 15px;
        width: 100%;
      }
    }
  }

  &__dailyVolume {
    &_value {
      width: 9vw;

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &__status {
    &_green,
    &_red {
      width: 9vw;

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &__dailyVolume {
    &_value {
      font-family: $MontserratRegular;
      font-size: 1.1vw;
      line-height: 1.2vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $greenLight;

      @media only screen and (min-width: 500px) and (max-width: 1024px) {
        font-size: $tablet16px;
        line-height: $tablet20px;
      }

      @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile24px;
      }
    }
  }

  &__buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 1vw;
    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      width: 100%;
      justify-content: flex-end;
      margin-top: $tablet20px;
    }

    @media only screen and (max-width: 499px) {
      width: 100%;
      justify-content: space-between;
    }

    button {
      //&:not(:last-child) {
      //    margin-right: 0.5vw;
      //
      //    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      //        margin-right: $tablet10px;
      //    }
      //
      //    margin-right: 1.2vw;
      //}
    }
  }


  &__previewBtn {
    @include mainBtnStyle;
    @include blueBtnWithOutBackground;
    height: 2.3vw;
  }

  &__stopBtn,
  &__deleteBtn,
  &__previewBtn,
  &__editBtn {
    @include mainBtnStyle;
    font-family: $MontserratRegular;
    height: 2.3vw;
    width: fit-content;
    min-width: 4.1vw;
    padding: 0 0.5vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
      min-width: 14.35vw;
      height: $tablet40px;
      padding: 0;
      line-height: normal;
    }

    @media only screen and (max-width: 499px) {
      width: 33%;
      height: $mobile34px;
      font-size: $mobile12px;
      line-height: $mobile12px;
    }
  }

  &__stopBtn {
    @include blueBtnWithOutBackground;
  }

  &__editBtn {
    @include greenBtnWithOutBackground;
  }

  &__deleteBtn {
    @include redBtnWithOutBackground;
  }
}

.previewIcon {
  cursor: pointer;
  width: 1.2vw;
  height: 1.2vw;
  min-width: 1.2vw;
  min-height: 1.2vw;

  @media only screen and (min-width: 500px) and (max-width: 1024px) {
    width: $tablet16px;
    height: $tablet16px;
    min-width: $tablet16px;
    min-height: $tablet16px;
  }

    @media only screen and (max-width: 499px) {
        width: $mobile16px;
        height: $mobile16px;
        min-width: $mobile16px;
        min-height: $mobile16px;
    }
}
